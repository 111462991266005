<template>
  <div class="map">
    <div id="map"></div>

    <!-- AR VISOR -->
    <transition :name="'fade'">
      <ARVisor
        v-if="arVisorShown"
        :startPoint="location"
        :endPoint="nextPointObject"
        :nextPointName="nameEnd"
        :userIsNearEnough="isUserNearEnough"
        :isFinished="routeIsFinished"
      ></ARVisor>
    </transition>

    <!-- CLOSE BUTTON -->
    <button class="round-btn close" @click="$router.go(-1)">
      <img
        src="img/02_generales/cerrar.png"
        alt="close button"
      />
    </button>

    <!-- CONTROLLS OVER MAP -->
    <div class="map-controlls">
      <button
        v-if="this.mapType === 'route'"
        class="cameraAR center"
        :class="{ disabled: !this.arVisorAvailable }"
        @click="showARVisor"
        :style="{
          'background-image': 'url(' + require('@/assets/camera.png') + ')',
        }"
      ></button>

      <button
        class="center"
        v-if="!isTravelingEnabled"
        @click="centerMap"
        :style="{
          'background-image': 'url(' + require('@/assets/ubicacion.png') + ')',
        }"
      ></button>
    </div>

    <div class="disclaimer-container">
      {{ $t("ARVisor.disclaimer") }}
    </div>

    <transition :name="'fade'">
      <div class="alertMap bg-layer opaque" v-if="alertMap">
        <div class="alert-container">
          <!-- slot for message -->
          <span>
            {{ $t("map.tooFarMessage") }}
          </span>
        
          <!-- single button Map -->
          <div class="options-container singleButton">
            <button class="option-btn locateMap" @click="centerMap">
              {{ $t("map.btnLocate") }}
            </button>
          </div>
          <!-- close alert button -->
          <button class="close-modal" @click="dismissMessage">
            <img src="img/02_generales/cerrar@2x.png" alt="close sidebar button"/>
          </button>
        </div>
      </div>
    </transition>

    <!-- DEBUG DISTANCES -->
    <div class="distances" v-if="developmentMode == 'development'">
      <span>Caminado: {{ distances.walked.toFixed(2) }} m.</span
      ><span>Porcentaje: {{ distances.percentage }}%</span
      ><span>Total: {{ distances.route.toFixed(2) }} m.</span>
      <span>Aprox. time left: {{ distances.aproxTime }}</span>
    </div>
  </div>
</template>

<script>
import * as turf from "@turf/turf";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-rotate/dist/leaflet-rotate-src.js";

import Park from "@/models/Park.js";
import ARVisor from "@/components/ARVisor.vue";
import { Analytic } from "./../../public/js/analytics/Analytic.js";
import MapUtilities from "../js/MapUtilities.js";
import { Howl } from "howler";

const $ = require('jquery');

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Mapa",
});

export default {
  name: "Map",
  components: {
    ARVisor,
  },
  computed: {
    arVisorAvailable() {
      return this.location && this.nextPointObject && !this.routeIsFinished;
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.$router.go();
      }
    },
    location(newVal) {
      if (!newVal) {
        return;
      }

      if (this.isTravelingEnabled) {
        setTimeout(() => {
          this.centerMapInPoint(this.location);
        }, 500);
      }
    },
    walkedCoordinates(newVal) {
      this.spotsLayer.eachLayer((l) => {
        if (l.options.name == "walked") {
          this.spotsLayer.removeLayer(l);
        }
      });

      L.polyline(newVal, {
        name: "walked",
        color: this.currentRoute.properties.color,
        weight: this.currentRoute.properties.weight,
      }).addTo(this.spotsLayer);
    },
    isTravelingEnabled(val) {
      if (val) {
        analytic.registerEvent("Map", "Travel enabled");
        this.leafletMap.setMaxBounds(null);
        this.leafletMap.dragging.enable();
        this.leafletMap.rotate = true;
        this.leafletMap.options.minZoom = this.map.maxZoom;
        this.updateRoutePopups();

        let angle = this.getAngleToNextPoint(this.nextPointObject);
        if (angle) {
          this.updateMapRotation(angle);
          this.updatePinRotation(angle);
        }

        setTimeout(() => {
          this.centerMapInPoint(this.location);
        }, 500);
      } else {
        analytic.registerEvent("Map", "Travel disabled");
        this.showTravelFinished = false;
        this.leafletMap.setMaxBounds(this.map.maxBounds);
        this.leafletMap.dragging.enable();
        this.leafletMap.rotate = false;
        this.leafletMap.options.minZoom = this.map.minZoom;
        this.updateRoutePopups();

        this.updateMapRotation(0);
        let angle = this.getAngleToNextPoint(this.nextPointObject);
        if (angle) {
          this.updatePinRotation(angle);
        }

        setTimeout(() => {
          this.centerMapInPoint(this.location);
        }, 500);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // delete route to paint
    this.$store.commit("routeToPaint", null);
    navigator.geolocation.clearWatch(this.geoLocationId);
    this.geoLocationId = null;
    //clearInterval(this.mapTimer);
    //this.mapTimer = null;

    clearInterval(this.fakeInterval);
    this.fakeInterval = null;

    analytic.registerEvent("Map", "closed");
    next();
  },
  data() {
    return {
      alertMap: false,
      bgLayer: true,
      alertType: "message", // message, confirm, cancel, prompt
      message: "",
      action: null,

      typeRoute: null,
      mapTimer: null,
      locationUser: null,
      settime: null,
      audioFinish: null,
      audioPlay: false,
      // basic
      park: null,
      leafletMap: null,
      locationMarker: null,
      locationMarkerUser: null,
      locationLayer: null,
      spotsLayer: null,
      debuggLayer: null,

      // prompt and alerts
      promptQuestion: null,
      showTravelFinished: false,
      isTravelingEnabled: false,

      // debugg
      fakeInterval: null,
      developmentMode: process.env.NODE_ENV,

      // special cases
      spotToPaint: null,
      bearingAngleForPin: null,

      isUserNearEnough: false,
      MINIMUM_PERCENTAGE_OF_ROUTE_COMPLETED: 95,
      routeIsFinished: false,
      sectionsDistances: [],
      nextPointObject: null,
      nextPoint: null,
      arVisorShown: false,
      outsideCoordinates: [],
      // compassShown: false,
      mapType: null,
      currentRoute: null,
      routeStart: null,
      routeStartMarker: null,
      nameStart: null,
      routeEnd: null,
      routeEndMarker: null,
      nameEnd: null,
      routes: [],
      spots: [],
      geoLocationId: null,
      location: null,
      map: {
        url: process.env.BASE_URL + "tiles/park/{z}/{x}/{y}.png",
        zoom: 17,
        minZoom: 17,
        maxZoom: 19,
        center: [38.787257, -9.390163],
        bearing: 0,
        bounds: [
          [
            [38.7821600, -9.4015000],
            [38.7898000, -9.3846500],
          ],
        ],
        maxBounds: [
          [
            [38.7821600, -9.4015000],
            [38.7898000, -9.3846500],
          ],
        ],
        options: {
          zoomSnap: 0.2,
          zoomControl: false,
          maxBoundsViscosity: 1,
          noWrap: true,
        },
        permanentPopUpOptions: {
          permanent: true,
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
          interactive: false,
          offset: [0, -30],
          className: "popUp-normal",
        },
        permanentBottomPopUpOptions: {
          permanent: true,
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
          interactive: false,
          offset: [0, 60],
          className: "popUp-bottom",
        },
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      },

      // markers
      jardim: null,
      pinIcon: null,
      pinIconWithArrow: null,
      debuggIcon: null,
      boxIcon: null,
      boxIconGray: null,
      boxIconFinish: null,
      distances: {
        route: 0,
        walked: 0,
        percentage: 0,
      },
      walkedCoordinates: [],
      walkedPosition: null,
    };
  },
  beforeDestroy() {
    clearTimeout(this.settime);
  },
  async mounted() {
    this.typeRoute = this.$store.state.typeRoute;
    await this.$nextTick();

    this.park = new Park(this.$store.state.parkData);
    this.mapType = this.$route.query.type;
    this.routes = this.park.routesGeo;
    this.spots = this.park.pointsGeo;

    this.setupCustomMarkers();
    this.setupLeafletMap();
    analytic.registerEvent("Map", "open");

    console.log(location.pathname + location.search);
    if (location.pathname + location.search == "/map?type=route") {
      this.settime = setTimeout(() => {
        this.$router.push("NextRoute");
      }, 40000);
    }

    this.audioFinish = new Howl({
      src: ["audios/desafio_correcto.mp3"],
      loop: false,
      volume: 0.7
    });
  },
  methods: {
    setupCustomMarkers() {
      this.pinIcon = L.icon({
        iconUrl: require("@/assets/pin.png"),
        iconSize: [35, 35],
        iconAnchor: [17, 17],
        className: 'pinIcon',
      });

      this.pinIconWithArrow = L.icon({
        iconUrl: require("@/assets/pinTest.png"),
        iconSize: [25, 51],
        iconAnchor: [12.5, 43],
      });

      this.debuggIcon = L.icon({
        iconUrl: require("@/assets/dummyPoint.png"),
        iconSize: [14, 14],
        iconAnchor: [7, 7],
      });

      this.boxIcon = L.icon({
        iconUrl: require("@/assets/boxIcon.png"),
        iconSize: [36, 36],
        iconAnchor: [18, 18],
      });

      this.boxIconGray = L.icon({
        className: "pinVisited",
        iconUrl: require("@/assets/boxIconGray.png"),
        iconSize: [36, 36],
        iconAnchor: [18, 18],
      });

      this.boxIconFinish = L.icon({
        iconUrl: require("@/assets/boxIcon.png"),
        iconSize: [36, 36],
        iconAnchor: [18, 18],
        className: "animated-icon",
        zIndexOffset: 1001,
      });
    },
    setupLeafletMap() {
      // MAP SETUP
      this.leafletMap = L.map("map", {
        center: this.map.center,
        bounds: this.map.bounds,
        maxBounds: this.map.bounds,
        maxBoundsViscosity: this.map.options.maxBoundsViscosity,
        noWrap: this.map.options.noWrap,
        zoomSnap: this.map.options.zoomSnap,
        zoomControl: this.map.options.zoomControl,
        attributionControl: this.map.attributionControl,

        rotate: true,
        touchRotate: false,
        rotateControl: false,
        bearing: this.map.bearing,
      });

      // TILE LAYER SETUP
      L.tileLayer(this.map.url, {
        attribution: this.map.attribution,
        maxZoom: this.map.maxZoom,
        minZoom: this.map.minZoom,
        maxNativeZoom: this.map.maxNativeZoom,
      }).addTo(this.leafletMap);

      // SPECIAL LAYERS
      this.locationLayer = new L.LayerGroup().addTo(this.leafletMap);
      this.spotsLayer = new L.LayerGroup().addTo(this.leafletMap);
      this.debuggLayer = new L.LayerGroup().addTo(this.leafletMap);

      //  MAP EVENTS
      this.leafletMap.on("load", this.mapIsReady);
      this.leafletMap.on("click", this.clickedCoordinate);
      this.leafletMap.on("drag", this.mapIsDragged);

      // Center the map
      this.leafletMap.setView(this.map.center, this.map.minZoom);
    },
    mapIsReady() {
      setTimeout(() => {
        this.startGeolocating();
      }, 250);

      // if aint development, remove debugg layer
      if (this.developmentMode !== "development") {
        this.leafletMap.removeLayer(this.debuggLayer);
      }

      switch (this.mapType) {
        case "route":
          // check if there is a special route to paint, or just use nextRoute
          if (this.$store.state.routeToPaint) {
            // any route to paint
            let data = this.$store.state.routeToPaint;
            analytic.registerEvent(
              "Map Route",
              data.properties.startId + " -> " + data.properties.endId
            );
            this.enableCurrentRoute(this.$store.state.routeToPaint);
            // set the need of sidebar when go back
            this.$store.commit("setSidebarNeeded", true);
            // enable by default the travel mode
            this.isTravelingEnabled = false;
          } else {
            // next route to paint
            let data = this.$store.state.nextRoute;
            analytic.registerEvent(
              "Map Next",
              data.properties.startId + " -> " + data.properties.endId
            );
            this.enableCurrentRoute(this.$store.state.nextRoute);
            // enable by default the travel mode
            this.isTravelingEnabled = true;
          }

          // any stuff to do when the map presents a route
          this.addRoute(this.currentRoute);

          this.setLabelJardim();

          break;
        case "spots":
          analytic.registerEvent("Map General", "spots");
          //this.addPointsOfInterest(this.spots);
          this.routesToPaint(this.routes, this.spots);

          this.setLabelJardim();

          break;
        case "spotsQR":
          analytic.registerEvent("Map General", "spots");
          this.addPointsOfInterestQR(this.spots);
          this.setLabelJardim();

          break;
        case "spot":
          this.spotToPaint = this.park.getSpotInfo(this.$route.query.spot);
          analytic.registerEvent(
            "Map Route",
            "single spot " + this.$store.state.currentRoute.id
          );
          this.addPointsOfInterest([this.spotToPaint]);
          this.$store.commit("setSidebarNeeded", true);
          this.centerMapInPoint(this.spotToPaint.geometry.coordinates);
          
          this.setLabelJardim();

          break;
        default:
          break;
      }

      // toggle and unbind the popup of marker in the layer group
      setTimeout(() => {
        this.spotsLayer.eachLayer(function (l) {
          l.openPopup().unbindPopup();
        });
      }, 250);
    },
    setLabelJardim(){
      var iconJardim = L.divIcon({
        className: 'my-div-icon',
        html: '<span class="jardimIngles">Jardim Inglês</span>'
      });

      this.jardim = L.marker([38.787215572376255, -9.395005000233311], {icon: iconJardim}).addTo(this.spotsLayer);
    },
    mapIsDragged() {
      this.leafletMap.panInsideBounds(this.map.bounds, { animate: false });
    },
    updateRoutePopups() {
      // find the type of the popup and assign the correct options object
      document.querySelector(".leaflet-popup-pane").innerHTML = "";
      if (this.isTravelingEnabled) {
        this.routeStartMarker.bindPopup(
          this.nameStart,
          this.currentRoute.properties.startPopupTypeInTravel === "normal"
            ? this.map.permanentPopUpOptions
            : this.map.permanentBottomPopUpOptions
        );
        this.routeEndMarker.bindPopup(
          this.nameEnd,
          this.currentRoute.properties.endPopupTypeInTravel === "normal"
            ? this.map.permanentPopUpOptions
            : this.map.permanentBottomPopUpOptions
        );
      } else {
        this.routeStartMarker.bindPopup(
          this.nameStart,
          this.currentRoute.properties.startPopupType === "normal"
            ? this.map.permanentPopUpOptions
            : this.map.permanentBottomPopUpOptions
        );
        this.routeEndMarker.bindPopup(
          this.nameEnd,
          this.currentRoute.properties.endPopupType === "normal"
            ? this.map.permanentPopUpOptions
            : this.map.permanentBottomPopUpOptions
        );
      }

      setTimeout(() => {
        this.spotsLayer.eachLayer(function (l) {
          l.openPopup().unbindPopup();
        });
      }, 500);
    },
    startGeolocating() {
      if (!("geolocation" in navigator)) {
        console.error("Geolocation not available");
        return;
      }

      this.geoLocationId = navigator.geolocation.watchPosition(
        (e) => {
          let location = [e.coords.latitude, e.coords.longitude];
          // if (!MapUtilities.isCoordInsidePark(location)) {
          //   return;
          // }
          this.updateLocation(location);
        },
        (e) => {
          console.log("Error: ", e);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 14000,
          timeout: 5000,
        }
      );

      /*this.mapTimer = setInterval(() => {
        navigator.geolocation.getCurrentPosition((e) => {
          let location = [e.coords.latitude, e.coords.longitude];
          this.updateLocation(location);
        },
        (e) => {
          console.log("Error: ", e);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 10000,
          timeout: 5000,
        });
      }, 1000);*/
    },
    // paintFairArea(route) {
    //   let collection = route.geometry.coordinates.map((coord) => {
    //     return turf.point(coord);
    //   });
    //   let featureCollection = turf.featureCollection(collection);
    //   let envelope =
    //     MapUtilities.getFairAreaOfFeatureCollection(featureCollection);
    //   let poly = L.polygon(envelope.geometry.coordinates);
    //   poly.addTo(this.leafletMap);
    // },
    addRoute(route) {
      this.spotsLayer.clearLayers();

      // line that shows the the route to follow
      L.polyline(route.geometry.coordinates, {
        name: route.properties.name,
        color: route.properties.color,
        weight: route.properties.weight,
        dashArray: route.properties.dash,
      }).addTo(this.spotsLayer);

      // line that shows the walked segment of the route
      L.polyline(this.walkedCoordinates, {
        name: "walked",
        color: route.properties.color,
        weight: route.properties.weight,
      }).addTo(this.spotsLayer);

      // create the markers for the start and end of the route
      this.routeStartMarker = L.marker(this.routeStart, {
        icon: this.boxIcon,
      });

      this.routeEndMarker = L.marker(this.routeEnd, {
        icon: this.boxIconFinish,
        id: this.currentRoute.properties.endId,
      });

      // find the type of the popup and assign the correct options object
      let startPopupOptions =
        route.properties.startPopupType === "normal"
          ? this.map.permanentPopUpOptions
          : this.map.permanentBottomPopUpOptions;

      let endPopupOptions =
        route.properties.endPopupType === "normal"
          ? this.map.permanentPopUpOptions
          : this.map.permanentBottomPopUpOptions;

      this.routeStartMarker.bindPopup(this.nameStart, startPopupOptions);
      this.routeEndMarker.bindPopup(this.nameEnd, endPopupOptions);

      // add the markers to the spotslayer
      this.routeStartMarker.addTo(this.spotsLayer);
      this.routeEndMarker.addTo(this.spotsLayer);

      // this.paintFairArea(this.currentRoute);
    },
    iconFinishClicked(e) {
      let clickedRouteId = e.sourceTarget.options.id;
      this.pushRouteTo(clickedRouteId);
    },
    routesToPaint(routes, poi) {
      this.spotsLayer.clearLayers();
      var allRoutes = routes.slice(0, -1);

      var finishedRoutes = this.$store.state.finishedRoutes;

      allRoutes.forEach((route) => {
        // line that shows the the route to follow
        L.polyline(route.geometry.coordinates, {
          name: route.properties.name,
          color: route.properties.color,
          weight: route.properties.weight,
          dashArray: route.properties.dash,
        }).addTo(this.spotsLayer);
      });

      if(finishedRoutes.includes("entrada") && finishedRoutes.includes("picadeiro")){
        var route0 = routes.find((item) => item.properties.id === "entrada-picadeiro")
        L.polyline(route0.geometry.coordinates, {
          name: route0.properties.name,
          color: "#75b962",
          weight: route0.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }
      console.log("tipo ruta 2", this.typeRoute);
      if(this.typeRoute == 2){
        console.log("tipo ruta 2");
        if(finishedRoutes.includes("palacio") && finishedRoutes.includes("picadeiro")){
          var route13 = routes.find((item) => item.properties.id === "palacio-picadeiro")
          L.polyline(route13.geometry.coordinates, {
            name: route13.properties.name,
            color: "#75b962",
            weight: route13.properties.weight,
            dashArray: "1",
          }).addTo(this.spotsLayer);
        }
      }

      if(finishedRoutes.includes("picadeiro") && finishedRoutes.includes("jardim")){
          var route12 = routes.find((item) => item.properties.id === "picadeiro-jardim")
          L.polyline(route12.geometry.coordinates, {
            name: route12.properties.name,
            color: "#75b962",
            weight: route12.properties.weight,
            dashArray: "1",
          }).addTo(this.spotsLayer);
        }

      if(finishedRoutes.includes("carrinho") && finishedRoutes.includes("palacio")){
        var route2 = routes.find((item) => item.properties.id === "carrinho-palacio")
        L.polyline(route2.geometry.coordinates, {
          name: route2.properties.name,
          color: "#75b962",
          weight: route2.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("patrimonio") && finishedRoutes.includes("jardim")){
        var route3 = routes.find((item) => item.properties.id === "patrimonio-jardim")
        L.polyline(route3.geometry.coordinates, {
          name: route3.properties.name,
          color: "#75b962",
          weight: route3.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("jardim") && finishedRoutes.includes("fonte")){
        var route4 = routes.find((item) => item.properties.id === "jardim-fonte")
        L.polyline(route4.geometry.coordinates, {
          name: route4.properties.name,
          color: "#75b962",
          weight: route4.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("fonte") && finishedRoutes.includes("carrinho")){
        var route5 = routes.find((item) => item.properties.id === "fonte-carrinho")
        L.polyline(route5.geometry.coordinates, {
          name: route5.properties.name,
          color: "#75b962",
          weight: route5.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("fonte") && finishedRoutes.includes("lagos")){
        var route6 = routes.find((item) => item.properties.id === "fonte-lagos")
        L.polyline(route6.geometry.coordinates, {
          name: route6.properties.name,
          color: "#75b962",
          weight: route6.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("lagos") && finishedRoutes.includes("quinta")){
        var route7 = routes.find((item) => item.properties.id === "lagos-quinta")
        L.polyline(route7.geometry.coordinates, {
          name: route7.properties.name,
          color: "#75b962",
          weight: route7.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("quinta") && finishedRoutes.includes("abegoaria")){
        var route8 = routes.find((item) => item.properties.id === "quinta-abegoaria")
        L.polyline(route8.geometry.coordinates, {
          name: route8.properties.name,
          color: "#75b962",
          weight: route8.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("abegoaria") && finishedRoutes.includes("estufas")){
        var route9 = routes.find((item) => item.properties.id === "abegoaria-estufas")
        L.polyline(route9.geometry.coordinates, {
          name: route9.properties.name,
          color: "#75b962",
          weight: route9.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("estufas") && finishedRoutes.includes("feteira")){
        var route10 = routes.find((item) => item.properties.id === "estufas-feteira")
        L.polyline(route10.geometry.coordinates, {
          name: route10.properties.name,
          color: "#75b962",
          weight: route10.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      if(finishedRoutes.includes("feteira") && finishedRoutes.includes("chalet")){
        var route11 = routes.find((item) => item.properties.id === "feteira-chalet")
        L.polyline(route11.geometry.coordinates, {
          name: route11.properties.name,
          color: "#75b962",
          weight: route11.properties.weight,
          dashArray: "1",
        }).addTo(this.spotsLayer);
      }

      poi.forEach((poiData) => {
        let poiMarker = L.marker(poiData.geometry.coordinates, {
          icon: this.spotIsAlreadyVisited(poiData)
            ? this.boxIconGray
            : this.boxIcon,
          id: poiData.properties.id,
        });

        let popupOptions =
          poiData.properties.type === "normal"
            ? this.map.permanentPopUpOptions
            : this.map.permanentBottomPopUpOptions;
        poiMarker.bindPopup(this.$t(poiData.properties.name), popupOptions);
        poiMarker.on("click", this.markerClicked);
        poiMarker.addTo(this.spotsLayer);
      });
    },
    addPointsOfInterest(poi) {
      this.spotsLayer.clearLayers();
      poi.forEach((poiData) => {
        console.log(poiData.geometry.coordinates);
        let poiMarker = L.marker(poiData.geometry.coordinates, {
          icon: this.spotIsAlreadyVisited(poiData)
            ? this.boxIconGray
            : this.boxIcon,
          id: poiData.properties.id,
        });

        let popupOptions =
          poiData.properties.type === "normal"
            ? this.map.permanentPopUpOptions
            : this.map.permanentBottomPopUpOptions;
        poiMarker.bindPopup(this.$t(poiData.properties.name), popupOptions);
        poiMarker.on("click", this.markerClicked);
        poiMarker.addTo(this.spotsLayer);
      });
    },
    addPointsOfInterestQR(poi) {
      this.spotsLayer.clearLayers();
      poi.forEach((poiData) => {
        let poiMarker = L.marker(poiData.geometry.coordinates, {
          icon: this.spotIsAlreadyVisited(poiData)
            ? this.boxIconGray
            : this.boxIcon,
          id: poiData.properties.id,
        });

        let popupOptions =
          poiData.properties.type === "normal"
            ? this.map.permanentPopUpOptions
            : this.map.permanentBottomPopUpOptions;
        poiMarker.bindPopup(this.$t(poiData.properties.name), popupOptions);
        poiMarker.on("click", this.markerClickedQR);
        poiMarker.addTo(this.spotsLayer);
      });
    },
    markerClicked(e) {
      let clickedRouteId = e.sourceTarget.options.id;

      let isBadgeAchieved =
        this.$store.getters.routeWasFinished(clickedRouteId);
      if (isBadgeAchieved) {
        analytic.registerEvent("Map", `visited point: ${clickedRouteId}`);
        this.pushRouteTo("Route", { id: clickedRouteId });
      }
    },
    markerClickedQR(e) {
      let clickedRouteId = e.sourceTarget.options.id;
      this.$store.commit("setRouteId", clickedRouteId);

      this.$router.push({ name: "Route" });
    },
    pushRouteTo(route, params) {
      if (params) {
        this.$router.push({ name: route, query: params }).catch(() => {});
      } else {
        this.$router.push(route, params).catch(() => {});
      }
    },
    spotIsAlreadyVisited(spot) {
      return this.$store.getters.routeWasFinished(spot.properties.id);
    },
    updateMapRotation(angle) {
      this.map.bearing = angle * -1;
      this.leafletMap.setBearing(this.map.bearing);
    },
    updatePinRotation(angle) {
      this.bearingAngleForPin = MapUtilities.deg2rad(angle);
      this.updatePinLocation(this.location, this.bearingAngleForPin);
      this.updatePinUser(this.locationUser);
    },
    enableCurrentRoute(route) {
      this.currentRoute = route;
      this.getRouteInformation();
      this.sectionsDistances = MapUtilities.getInfoOfRouteSections(
        this.currentRoute
      );
    },
    getRouteInformation() {
      this.routeStart = this.currentRoute.geometry.coordinates[0];
      this.nameStart = `${this.$t(this.currentRoute.properties.start)}`;
      this.routeEnd =
        this.currentRoute.geometry.coordinates[
          this.currentRoute.geometry.coordinates.length - 1
        ];
      this.nameEnd = `${this.$t(this.currentRoute.properties.end)}`;
    },
    updateLocation(location) {
      this.location = location;
      this.locationUser = location;
      switch (this.mapType) {
        case "route":
          this.isUserNearEnough = MapUtilities.checkIfLocationNearEnoughToRoute(
            this.currentRoute,
            location
          );

          if (this.isUserNearEnough) {
            this.outsideCoordinates = [];
            let routeString = turf.lineString(
              this.currentRoute.geometry.coordinates
            );
            let nearPointOnRoute = MapUtilities.nearestPointToLocation(
              this.currentRoute,
              this.location
            );
            this.walkedCoordinates = this.getWalkedRoute(
              [this.routeStart[0], this.routeStart[1]],
              nearPointOnRoute.geometry.coordinates,
              routeString
            );
            this.location =
              this.walkedCoordinates[this.walkedCoordinates.length - 1];

            this.updateDistances(this.walkedCoordinates);
            this.updateNextPoint();
          } else {
            this.outsideCoordinates.push(location);
            this.walkedCoordinates = [];
            this.walkedPosition = null;
            this.nextPointObject = null;
            this.routeIsFinished = false;
            this.updatePinLocation(this.location, null);
          }

          // check if the last coord is near enough else throw message
          if (this.outsideCoordinates.length > 0 && this.isTravelingEnabled) {
            let lastLoggedCoord =
              this.outsideCoordinates[this.outsideCoordinates.length - 1];
            let lastLoggedCoordIsNearEnough =
              MapUtilities.checkIfLocationNearEnoughToRoute(
                this.currentRoute,
                lastLoggedCoord
              );

            if (!lastLoggedCoordIsNearEnough) {
              this.alertMap = true;
              this.isTravelingEnabled = false;
            }
          }

          break;
        case "spots":
        case "spotsQR":
        case "spot":
          // case of map presenting just the important spots
          this.routeStart = null;
          this.routeEnd = null;
          this.updatePinLocation(location, null);
          break;

        default:
          break;
      }
    },
    updatePinUser(location){
      this.locationMarkerUser = L.marker(location, {
        icon: this.pinIcon,
      });
      this.locationMarkerUser.addTo(this.locationLayer);
    },
    updatePinLocation(location, angle) {
      var fixedAngle = angle;
      if (angle) {
        if (this.isTravelingEnabled) {
          let pinAngleInDeg = MapUtilities.rad2deg(angle);
          let operationResult = pinAngleInDeg + this.map.bearing;
          fixedAngle = MapUtilities.deg2rad(operationResult);
        }
      }

      this.locationLayer.clearLayers();
      this.locationMarker = L.marker(location, {
        icon: angle ? this.pinIconWithArrow : this.pinIcon,
        rotation: angle ? fixedAngle : 0,
        zIndexOffset: 1000,
      });
      this.locationMarker.addTo(this.locationLayer);
    },
    updateNextPoint() {
      this.routeIsFinished = false;
      for (let index = 0; index < this.sectionsDistances.length; index++) {
        const section = this.sectionsDistances[index];
        // check if certain percentage of the route was completed, if it does, remove compass
        //  and remove point
        if (
          this.distances.percentage >=
          this.MINIMUM_PERCENTAGE_OF_ROUTE_COMPLETED
        ) {
          this.routeIsFinished = true;
          this.nextPoint = null;
          this.nextPointObject = null;
          if (this.isTravelingEnabled) {
            this.isTravelingEnabled = false;
          }
          $(".animated-icon").addClass("animated-icon-animation");
          this.routeEndMarker.on("click", this.iconFinishClicked);
          if (!this.audioPlay) {
            this.audioFinish.play();
            this.audioPlay = true;
          }
          break;
        }else {
          this.audioPlay = false;
          $(".animated-icon").removeClass("animated-icon-animation");
        }

        // if section distance from start its bigger than the distance walked
        if (
          section.distanceFromStart.toFixedNumber(2) >
          this.distances.walked.toFixedNumber(2)
        ) {
          this.nextPoint = section;
          break;
        }
      }

      // Next point objext, util for the route direction arrow
      if (this.routeIsFinished != true) {
        const lat = this.nextPoint.end[0];
        const lng = this.nextPoint.end[1];
        let debuggPoint = turf.point([lat, lng]);
        this.nextPointObject = debuggPoint.geometry.coordinates;
      } else {
        this.nextPointObject = null;
      }

      // get angle
      if (this.nextPointObject) {
        let angle = this.getAngleToNextPoint(this.nextPointObject);
        if (this.isTravelingEnabled) {
          this.updateMapRotation(angle);
          this.updatePinRotation(angle);
        } else if (angle) {
          this.updatePinRotation(angle);
        }
      } else {
        this.updatePinLocation(this.location, null);
      }
    },
    getAngleToNextPoint(nexPoint) {
      if (!nexPoint) {
        return;
      }
      return MapUtilities.findDegreeBetweenTwoPoints(this.location, nexPoint);
    },
    updateDistances() {
      this.distances.route = MapUtilities.km2m(
        MapUtilities.getRouteDistance(this.currentRoute.geometry.coordinates)
      );
      this.distances.walked = MapUtilities.km2m(
        MapUtilities.getRouteDistance(this.walkedCoordinates)
      );
      this.distances.percentage = (
        (this.distances.walked * 100) /
        this.distances.route
      ).toFixed(2);

      this.distances.aproxTime = MapUtilities.getAproxTime(
        this.distances.route - this.distances.walked
      );
    },
    getWalkedRoute(start, stop, line) {
      var sliced = turf.lineSlice(start, stop, line);
      this.walkedPosition =
        sliced.geometry.coordinates[sliced.geometry.coordinates.length - 1];
      return sliced.geometry.coordinates;
    },
    centerMapInPoint(point) {
      if (this.leafletMap != undefined) {
        if (point != null) {
          this.leafletMap.panTo(point);
        } else {
          this.leafletMap.panTo(this.map.center);
        }
      }
    },
    centerMap() {
      this.alertMap = false;
      console.log("center");
      if (this.location) {
        this.centerMapInPoint(this.location);
      } else if (this.routeStart) {
        this.centerMapInPoint(this.routeStart);
      } else {
        this.centerMapInPoint();
      }
    },

    // state toggle
    showARVisor() {
      this.arVisorShown = true;
    },
    dismissARVisor() {
      this.arVisorShown = false;
    },
    // Debugg: Clicked coordinate
    clickedCoordinate(e) {
      var coord = e.latlng;
      console.log(
        "You clicked the map at latitude: " + coord.lat + ", " + coord.lng
      );
    },
    dismissMessage() {
      this.alertMap = false;
    },
  },
};
</script>

<style lang="scss">
.map {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;

  .disclaimer-container {
    position: absolute;
    color: white;
    background-color: var(--app-modalBg);
    border-radius: 0.5rem;
    width: 95%;
    flex: auto;
    bottom: 1vh;
    left: 0;
    right: 0;
    text-align: left;
    margin: auto;
    padding: 0.5rem 1rem;
    font-family: "Arial", monospace, sans-serif;
    font-size: 0.8rem;
    z-index: 1;
  }

  .pinIcon{
    pointer-events: none;
  }

  .animated-icon-animation {
    //animation: glow 2.5s infinite alternate-reverse linear;
    animation: pulse 1.7s linear infinite;
    -webkit-animation: pulse 1.7s linear infinite;
    -o-animation: pulse 1.7s linear infinite;
    -moz-animation: pulse 1.7s linear infinite;
  }

  #map {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .jardimIngles{
    margin-left: -12px;
    margin-top: -12px;
    text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
             1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
  }

  .leaflet-marker-pane {
    .debuggPoint {
      z-index: 500 !important;
      background-color: blue;
      border: 0.2rem solid hotpink;
    }
  }

  .leaflet-popup-pane {
    z-index: 200;

    .leaflet-popup {
      .leaflet-popup-content-wrapper {
        background-color: rgba(0, 0, 0, 0.85);
        z-index: 200;
      }

      .leaflet-popup-content-wrapper .leaflet-popup-content {
        background-color: transparent;
        color: white;
        font-family: "Arial", monospace, sans-serif;
        font-size: 0.8rem;
        margin: 0.4rem 0.5rem;
        line-height: normal;
        text-align: center;
        letter-spacing: 1px;
      }

      .leaflet-popup-tip-container {
        .leaflet-popup-content-wrapper,
        .leaflet-popup-tip {
          background-color: rgba(0, 0, 0, 0.85);
        }
      }

      .leaflet-popup-close-button {
        display: none;
      }
    }
  }

  .close {
    z-index: 401;
    left: unset;
    right: 1rem;
  }

  .map-controlls {
    display: flex;
    flex-flow: column;
    position: absolute;
    right: 1rem;
    bottom: 3.5rem;
    z-index: 401;

    button,
    .cameraAR,
    .compass {
      position: relative;
      width: 3rem;
      height: 3rem;
      margin: 0.2rem;
      border: unset;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .start-travel {
      background-size: 60%;
      background-color: black;
      border-radius: 10rem;
      border: 0.3rem solid var(--app-orange);
    }
  }

  .distances {
    position: absolute;
    font-family: monospace;
    font-size: 0.9rem;
    color: black;
    background-color: rgba(214, 214, 214, 0.9);
    margin: 1rem;
    padding: 0.5rem;
    bottom: 0;
    z-index: 999;
    pointer-events: none;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    max-width: 60%;
    font-weight: bold;

    span {
      margin-bottom: 0.1rem;
    }
  }

  .bg-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  z-index: 1000;

  &.opaque {
    background-color: var(--app-blackTransparency);
  }

  .alert-container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--app-modalBg);
    border: 0.2rem solid var(--app-modalBorder);
    border-radius: 0.5rem;
    width: 70%;
    padding: 0.5rem;
    font-family: "Arial", monospace, sans-serif;
    font-size: 1.2rem;

    .close-modal {
      position: absolute;
      width: 2.6rem;
      height: 2.6rem;
      top: -1rem;
      right: -1rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      z-index: 1;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    span {
      margin-bottom: 0.5rem;
    }

    &.onlyMessage {
      padding: 1rem;
      span {
        margin-bottom: 0;
      }
    }

    .options-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0.5rem;
      width: 100%;

      .option-btn {
        position: relative;
        margin: 0rem;
        padding: 0.5rem 1rem;
        border: unset;
        width: auto;
        font-size: 0.8rem;
        background-color: var(--app-orange);
        color: var(--app-black);
        font-family: "Arial-black", monospace, sans-serif;
        border-radius: 0.4rem;
        text-transform: uppercase;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .goTos1, .locateMap {
        position: relative;
        width: max-content;
        background-color: var(--app-orange);
        color: #444444;
        font-family: "Arial-black", monospace, sans-serif;
        border-radius: 0.8rem;
        text-align: center;
        box-shadow: 0px 3px 4px 0px #707070;
        transition: all 0.2s ease;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        padding: 0.5rem 0.7rem;
        text-transform: uppercase;
        margin: 0 auto;
        margin-top: 0.5rem;
      }
    }
  }
}
}

@keyframes glow {
  0% {
    filter: drop-shadow(0px 0px 0.25rem #336FB7);
  }

  60% {
    filter: drop-shadow(0.25rem -0.25rem 0.25rem #336FB7);
  }
}

@keyframes pulse {
  0% {
    width: 36px;
    height: 36px;
    //filter: drop-shadow(0rem 0rem 0rem white);
    //-webkit-filter: drop-shadow(0rem 0rem 0rem white);
  }

  50% {
    width: 50px;
    height: 50px;
    //filter: drop-shadow(0.25rem -0.25rem 0.25rem white);
    //-webkit-filter: drop-shadow(0.25rem -0.25rem 0.25rem white);
  }

  100% {
    width: 36px;
    height: 36px;
    //filter: drop-shadow(0rem 0rem 0rem white);
    //-webkit-filter: drop-shadow(0rem 0rem 0rem white);
  }
}

@-webkit-keyframes pulse {
  0% {
    width: 36px;
    height: 36px;
    //filter: drop-shadow(0rem 0rem 0rem white);
    //-webkit-filter: drop-shadow(0rem 0rem 0rem white);
  }

  50% {
    width: 50px;
    height: 50px;
    //filter: drop-shadow(0.25rem -0.25rem 0.25rem white);
    //-webkit-filter: drop-shadow(0.25rem -0.25rem 0.25rem white);
  }

  100% {
    width: 36px;
    height: 36px;
    //filter: drop-shadow(0rem 0rem 0rem white);
    //-webkit-filter: drop-shadow(0rem 0rem 0rem white);
  }
}
</style>
