<template>
  <div class="progress-bar" :class="{ disabled: this.$store.state.disabledLanguages }">
    <div class="progress-cont">
        <!--<figure class="bar">
            <img :src="setImgBar" alt="">
        </figure>-->
        <div class="bar">
          <div class="loading-bar" v-for="n in numPoints" :key="n"></div>
        </div>
        <figure class="star-bar" @click="goRewards">
            <img :src="setStar" alt="">
        </figure>
    </div>
  </div>
</template>

<script>
const $ = require('jquery');
window.$ = $;

export default {
  name: "progress-bar",
  mounted() {
    this.setBar();
  },
  computed: {
    setImgBar(){
        let totalRewards = this.$store.state.finishedChallenges.length;
        if(this.typeRoute != 2){
            if(totalRewards == null){
                return require(`@/assets/progress/barra_7_0.png`);
            }else{
                return require(`@/assets/progress/barra_7_${totalRewards}.png`);
            }
        }else{
            if(totalRewards == null){
                return require(`@/assets/progress/barra_12_0.png`);
            }else{
                return require(`@/assets/progress/barra_12_${totalRewards}.png`);
            }
        }
    },
    setStar(){
        let isWinnerCode = this.$store.state.winnerCode;
        if(isWinnerCode != null){
            return require(`@/assets/progress/star_on.png`);
        }else{
            return require(`@/assets/progress/star_off.png`);
        }
    }
  },
  data() {
    return {
      typeRoute: this.$store.state.typeRoute,
      numPoints: this.$store.state.numRoutes,
    };
  },
  watch: {
    "$store.state.finishedChallenges": function () {
      this.setBar();
    },
  },
  methods: {
    setBar(){
      var totalPrizes = this.$store.state.finishedChallenges.length;
      const bars = document.querySelectorAll(".loading-bar");
      console.log("bar", bars.length);
      const barsCount = bars.length;
      const percent = (100/this.numPoints)*totalPrizes; // <= Change percentage here
      const percentPerBar = 100 / barsCount;
      const barsGap = `${100 / barsCount}px`;

      console.log(percent, percentPerBar, barsGap);

      document.documentElement.style.setProperty("--barsCount", barsCount);
      document.documentElement.style.setProperty("--barsGap", barsGap);

      bars.forEach((bar, index) => {
        const diff = ((percent - percentPerBar * index) / percentPerBar) * 100;
        //const progress = window.getComputedStyle(bar, "::before");
        const barBackground = document.querySelector(
          `.loading-bar:nth-child(${index + 1})`
        ).style;
        barBackground.setProperty(
          "background",
          `linear-gradient(to right, #99d886 ${diff}%, rgba(0,0,0,0) ${diff}%`
        );
      });
    },
    goRewards(){
        this.$router.push("Rewards");
    }
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  display: flex;
  flex-flow: row;
  position: absolute;
  top: 5rem;
  z-index: 1;
  width: 100%;
  height: 2rem;
  justify-content: center;

  &.disabled {
    pointer-events: none;
  }

  .progress-cont{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 90%;

    .bar{
        width: 87.5%;
        display: flex;
        border-radius: 50px;
        background: linear-gradient(0deg, #969696 60%, #3c3c3c 100%);

        .loading-bar {
          --bar-height: 20px;
          position: relative;
          width: calc(100% / var(--barsCount));
          height: var(--bar-height);
          overflow: hidden;
          border: 1px solid #446936;
          border-right: 2px solid #446936;
        }

        .loading-bar:nth-last-child(1) {
          border-top-right-radius: calc(var(--bar-height) / 2);
          border-bottom-right-radius: calc(var(--bar-height) / 2);
          border-right: 1px solid #446936;
        }

        .loading-bar:nth-child(1) {
          border-top-left-radius: calc(var(--bar-height) / 2);
          border-bottom-left-radius: calc(var(--bar-height) / 2);
        }

        .loading-bar::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: var(--background);
        }
    }

    .star-bar{
        width: 2.5rem;
        height: 2.5rem;
    }

    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
  }
}
</style>
